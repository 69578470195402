<template>
    <div  v-disable-all="!pageMeta.permissions.edit">
        <div v-if="!loading" class="rec-data-div" :class="pageMeta.component_name.toLowerCase()+'-page'">
            <ValidationObserver ref='form_release'>
            <form @submit.prevent="updateRecord">
                <div class="flex space-between">
                    <div class="name-id">
                        <FormClient
                            :client_id="client_id"
                            :siggys="original_signature_ids.length"
                            :form_data_id="record_id"
                            @getClient="getClient"
                        /><br>
                    </div>
                    <div class="align-right">
                        <FormCompany @getCompany="getCompany"/>
                    </div>
                </div>
                <div>
                    <h2 class="margin-0">Release of Information Consent</h2>
                </div>

                <button type="button" @click="signature_ids=[]" v-if="signature_ids.length && original_signature_ids.length">
                    Edit & Create Addendum
                </button>
                <button v-if="original_signature_ids.length && !signature_ids.length" class="align-right" type="button" @click="signature_ids=original_signature_ids">
                    Cancel Edit
                </button>
                <div class="dark-text header-w-bg addendum" v-if="original_signature_ids.length && !signature_ids.length">
                    You are now editing the original signed document to create an addendum
                </div>

                <hr class="m-15" />

                <!-- put generated code BELOW here -->

                <div class="dark-text header-w-bg">Release of Information Consent</div>
                <div class="dark-text top-30 bottom-30 font-13 bold">I, {{ clientName }} authorize {{ this.company.company_name }} to:<dropdown v-model="rec.authorize" id="authorize" label="" :options='["Disclose the following Information","Receive the following Information","Disclose and Receive the following Information"]' required/></div>
                <div class="dark-text top-30 bottom-30 font-13">Please select the options that you authorize:</div>
                <div class="flex wrap space-between colx2">
                    <Checkbox v-model="rec.authorize_options.medical_history" label="Medical/mental health history and evaluation(s)" />
                    <Checkbox v-model="rec.authorize_options.discharge" label="Discharge or transfer summaries" />
                    <Checkbox v-model="rec.authorize_options.diagnosis" label="Diagnosis" />
                    <Checkbox v-model="rec.authorize_options.continuation_of_care_plan" label="Continuation of care plan" />
                    <Checkbox v-model="rec.authorize_options.mental_health_evaluations" label="Mental health evaluations" />
                    <Checkbox v-model="rec.authorize_options.medication_information" label="Medication Information" />
                    <Checkbox v-model="rec.authorize_options.developmental_history" label="Developmental and/or social history" />
                    <Checkbox v-model="rec.authorize_options.demographic_information" label="Demographic Information" />
                    <Checkbox v-model="rec.authorize_options.progress_notes" label="Progress notes" />
                    <Checkbox v-model="rec.authorize_options.progress_in_treatment" label="Progress in Treatment" />
                    <Checkbox v-model="rec.authorize_options.treatment_plans" label="Treatment plans or summaries" />
                    <Checkbox v-model="rec.authorize_options.other_1" label="Other" />
                    <div><div v-if='cbReq1' style='color:red'>You must select at least one checkbox</div></div>
                    <Input type="text" v-model="rec.other_text_1" id="other" label="Specify" :required='other_field_required(rec.authorize_options.other_1)'/>
                </div>
                <div class="dark-text top-30 bottom-30 font-13 bold">Information to be disclosed to and/or from, based on choice above in section 1:</div>
                <Input type="text" v-model="rec.name_or_title" id="name or title" label="Name of Person or Title of Organization" required/>
                <Input type="text" v-model="rec.phone" id="phone" label="Phone" rules="phone"/>
                <Input type="email" v-model="rec.email" label="Email" />
                <Input type="text" v-model="rec.fax" id="fax" label="Fax" rules="phone" />
                <div class="dark-text top-30 bottom-30 font-13 bold">For the following purposes(Select all that apply):</div>
                <Checkbox v-model="rec.purposes.planning_treatment_programs" label="Planning treatment programs" />
                <Checkbox v-model="rec.purposes.continuing_treatment_programs" label="Continuing treatment programs" />
                <Checkbox v-model="rec.purposes.eligibility_for_benefits" label="Determining eligibility for benefits" />
                <Checkbox v-model="rec.purposes.updating_records" label="Updating records" />
                <Checkbox v-model="rec.purposes.other_2" label="Other" />
                <Input type="text" v-model="rec.other_text_2" id="other" label="Specify"  :required='other_field_required(rec.purposes.other_2)'/>
                <div><div v-if='cbReq2' style='color:red'>You must select at least one checkbox</div></div>
                <div class="dark-text top-30 bottom-30 font-13 bold">To expire on:</div>
                <dropdown v-model="rec.expire_on" id="expire date" label="" :options='["Upon completion or withdrawal from therapy","On specified date"]' required/>
                <div v-if='expire_true'><Input type="date" id="expire date" v-model="rec.expire_date" label=""  :required='expire_true'/></div>
                <p class="dark-text top-30 bottom-30 font-13 bold">I understand this authorization is voluntary and I may update and revoke this consent at any time. I confirm I was informed and agree to what information will be shared, with whom, and for what purposes. I understand that my records are protected under state and federal laws and cannot be disclosed without my written consent. I understand I have the right to request a copy of this consent.</p>

                <!-- put generated code ABOVE here -->

                <div class="align-right" v-if="!signature_ids.length">
                    <button v-if="original_signature_ids.length && !signature_ids.length" class="align-right inline-block secondary right-15" type="button" @click="signature_ids=original_signature_ids">
                        Cancel Edit
                    </button>
                    <ConfirmButton class="inline-block" @click="updateRecord()" :canedit="isEditable" />

                </div>
                <FormSignature
                    :user_id="user_id"
                    :client_id="client.id"
                    :signature_ids="signature_ids"
                    ref="form_signatures"
                    @getSignatures="getSignatures"
                    :show_witness="true"
                    v-if="signature_ids.length > 0"
                />
                <FormSignatureButton
                    :rec="rec"
                    :original_rec="original_rec"
                    :signatures_info="signatures_info"
                    :fileName="pageMeta.component_name"
                    :signature_ids="signature_ids"
                    :record_id="record_id"
                    @resetView="reloadData"
                    @fail="fail2sign"
                    @showModal="showPdfModal"
                    :save="updateRecord"
                    @commenceValidate="makeValidation"
                    :mustValidate="true"
                    :isValid="validYes"
                />
            </form>
            </ValidationObserver>
            <VIfWorkableModal
                :hasCloseButton="false"
                data-html2canvas-ignore="true"
                v-if="showCreatePdfModal"
            >
                <SignConvertPdfModal />            
            </VIfWorkableModal>
        </div>
    </div>
</template>
<script>
    import SignConvertPdfModal from '@/components/formpieces/SignConvertPdfModal'
    import FormClient from '@/components/formpieces/FormClient'
    import FormCompany from '@/components/formpieces/FormCompany.vue';
    import FormSignature from '@/components/formpieces/FormSignatureNew';
    import FormSignatureButton from '@/components/formpieces/FormSignatureButtonNew'
    import VIfWorkableModal from '@/components/general/modals/vIfWorkableModal.vue';
    import { ValidationObserver } from 'vee-validate';
    import { dryalex } from '@/mixins/dryalex'
    import { merge } from 'lodash'

    const cbTotal = obj => Object.values(obj).reduce((a, b) => a + b);

    export default {
        name: 'StandardReleaseInformation',
        props: {
            record_id: {
                type: [String, Number],
                required : false,
                default : 0
            },
        },
        components: { FormClient, FormSignature, VIfWorkableModal, FormCompany, FormSignatureButton, ValidationObserver, SignConvertPdfModal },
        data() {
            return {
                loading     : 1,
                updating    : 0,
                isEditable  : 1,
                user_id     : 0,
                client_id   : 0,
                page_id     : 0,
                rec         : {},
                original_rec : {},
                signature_ids : [],
                original_signature_ids : [],
                showCreatePdfModal : false,
                api_signs_needed: null,
                signatures_info: null,
                client: {},
                company: {},
                rows: [],
                level_id: 0,
                level: 'company',
                options: [],
                this_record_id : 0,
                router_push : 0,
                cbReq1: false,
                cbReq2: false,
                validYes: false,
            }
        },
        computed: {
            expire_true() {
                return (this.rec.expire_on == "On specified date");
            },
            clientName() {
                return this.client.first_name + " " + this.client.last_name;
            }
        },
        methods: {
            async fail2sign() {
                this.showPdfModal(false)
            },
            async reloadData(signature){
                this.showCreatePdfModal = false;
                this.signature_ids.push(signature)
            },
            showPdfModal(bool){
                this.showCreatePdfModal = bool;
            },
            getClient(data){
                this.client = data;
            },
            getCompany(data){
                this.company = data;
            },
            //signatures that come from the FormSignatures component
            getSignatures(data){
                this.signatures_info = data ? data : [];
            },
            async makeValidation() {
                this.validYes = false;
                const form = this.$refs.form_release;
                const isValid = await form.validate();

                let review1 = this.checkboxset1_required();
                let review2 = this.checkboxset2_required();

                if (!isValid || review1 || review2) {
                    this.$toasted.error("A checkbox is required to be filled.");
                    return;
                }
                this.cbReq1 = false;
                this.cbReq2 = false;
                this.validYes = true;
            },
            async updateRecord(e) {
                const form = this.$refs.form_release;
                const isValid = await form.validate();

                let review1 = this.checkboxset1_required();
                let review2 = this.checkboxset2_required();

                if (!isValid || review1 || review2) {
                    this.$toasted.error("A checkbox is required to be filled.");
                    return;
                }
                this.cbReq1 = false;
                this.cbReq2 = false;

                if (!this.updating) {
                    this.updating  = 1
                    try{e.preventDefault()}catch(error){/**/}

                    let xthis = await dryalex.form_data_update_record(this, e)
                    merge(this, xthis)
                    if (this.router_push) {
                        this.router_push = 1
                        this.$forceUpdate()
                    }
                    this.original_signature_ids = this.signature_ids
                }
            },
            async init() {
                let xthis = await dryalex.form_data_init(this)
                merge(this, xthis)
                this.loading = 0
            },
            other_field_required(oth) {
                return (oth > 0);
            },
            checkboxset1_required() {
                let response = true; //default
                if (cbTotal(this.rec.authorize_options) > 0) {
                    this.cbReq1 = false;
                    response = false;
                } else {
                    this.cbReq1 = true;
                }
                return response;
            },
            checkboxset2_required() {
                let response = true; //default
                if (cbTotal(this.rec.purposes) > 0) {
                    this.cbReq2 = false;
                    response = false;
                } else {
                    this.cbReq2 = true;
                }
                return response;
            },
        },
        async created() {
            await this.init()
        },
        watch: {
            showCreatePdfModal(newVal){
                if(window.innerWidth >= 768 && newVal == true) this.openModalOverlay();
                if(newVal == false){this.closeModalOverlay()}
            },
        }
    }
</script>